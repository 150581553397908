import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import { ButtonTransparentAccent } from 'components/ui/button';
import QRCodeCell from 'components/ui/table/cells/QRCodeCell';

const getQrCodeElementId = (channelName) =>
  `qrcode-${channelName?.replace(/\s/g, '_')}`;

export function QrCodeModal({ open, value, onClose, channelName }) {
  const qrCodeElementId = getQrCodeElementId(channelName);
  return (
    <Modal dimmer onClose={onClose} open={open} size="small">
      <Modal.Content>
        <QRCodeCell
          elementId={qrCodeElementId}
          value={value}
          title={channelName}
          defaultDisplayed
        />
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentAccent
          inverted
          onClick={onClose}
          content={t`close`}
        />
      </Modal.Actions>
    </Modal>
  );
}
QrCodeModal.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  channelName: PropTypes.string,
};
QrCodeModal.defaultProps = { open: false, value: null, channelName: null };

export default QrCodeModal;
