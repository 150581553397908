import React from 'react';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SentimentCell from 'components/ui/cells/SentimentCell';

import commonPropTypes from 'utils/commonPropTypes';
import {
  contributionPercentFormatter,
  floatFormatter,
  dateFormatter as monthFormatter,
  numberFormatter,
  zeroPrecisionPercentFormatter,
} from 'utils/formatter';
import { capitalize, getDescendantProp } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const rechartsTooltipFields = {
  date: {
    // Do not show a label for date
    formatter: monthFormatter,
  },
  source: {
    value: 'source',
    isEntity: true,
  },
  ontology_name: {
    value: 'ontology_name',
    isEntity: true,
  },
  concept_id: {
    isEntity: true,
  },
  concept: {
    isEntity: true,
  },
  product_hierarchy_group: {
    isEntity: true,
  },
  product_hierarchy: {
    isEntity: true,
  },
  cumulated: {
    label: t`cumulated-volume`,
    formatter: numberFormatter,
    stroke: svars.volumeStrokeColor,
  },
  n_chunks: {
    label: t`volume`,
    formatter: numberFormatter,
    stroke: svars.volumeStrokeColor,
  },
  share_of_extracts: {
    label: t`share-of-extracts`,
    formatter: zeroPrecisionPercentFormatter,
  },
  volume: {
    label: t`volume`,
    formatter: numberFormatter,
    stroke: svars.volumeStrokeColor,
  },
  csat: {
    label: t`CSAT-score`,
    formatter: numberFormatter,
    stroke: svars.colorComplementary,
  },
  score: {
    label: t`score`,
    formatter: numberFormatter,
    stroke: svars.colorComplementary,
  },
  average_sentiment: {
    label: t`sentiment`,
    formatter: floatFormatter,
    content: (value) => <SentimentCell sentiment={value} />,
  },
  n_positive: {
    label: t`positive-volume`,
    formatter: numberFormatter,
    stroke: svars.absoluteMaxColor,
  },
  n_neutral: {
    label: t`neutral-volume`,
    formatter: numberFormatter,
    stroke: svars.absoluteMidColorStroke,
  },
  n_negative: {
    label: t`negative-volume`,
    formatter: numberFormatter,
    stroke: svars.absoluteMinColor,
  },
  n_polarities: {
    label: t`positive-neutral-negative-volume(monthly-volume)`,
    content: (value, payload) => (
      <div>
        {['n_positive', 'n_neutral', 'n_negative']
          .map((dataKey) => (
            <Indicator
              key={`tind-${dataKey}`}
              id={`sentiment-${dataKey}`}
              data-testid={`sentiment-${dataKey}`}
              style={{ color: rechartsTooltipFields[dataKey].stroke }}
            >
              {numberFormatter(payload[dataKey])}
            </Indicator>
          ))
          .reduce(
            (acc, item) =>
              acc
                ? [
                    ...acc,
                    <Indicator // eslint-disable-line
                      style={{ padding: `0 ${svars.spaceNormal}` }}
                      key={`sep-${acc.length}`}
                    >
                      /
                    </Indicator>,
                    item,
                  ]
                : [item],
            null
          )}
      </div>
    ),
  },
  rolling_average_sentiment: {
    label: t`sentiment(smoothed)`,
    formatter: floatFormatter,
    stroke: svars.volumeStrokeColor,
  },
  top_index: {
    label: t`contribution`,
    formatter: contributionPercentFormatter,
  },
};

export /**
 * Get tooltip fields metadata for a list of field keys.
 *
 * @param {String} [...keys]
 */
const getRechartsTooltipFields = (
  [...keys],
  entityLabelFormatter = null,
  extraFields = [],
  headerField = null,
  dateFormatter = null
) => [
  ...(headerField ? [headerField] : []),
  ...keys.map((key) => {
    const field = rechartsTooltipFields[key] || { isEntity: true };
    field.key = key;
    if (field.key === 'date' && dateFormatter) {
      field.formatter = dateFormatter;
    } else if (field.isEntity && entityLabelFormatter) {
      field.formatter = entityLabelFormatter(key);
    }
    return field;
  }),
  ...extraFields,
];

export const TooltipContainer = styled.div`
  user-select: none;
  max-width: ${svars.tooltipMaxWidth}px;
  background-color: ${svars.colorWhite};
  opacity: ${svars.tooltipOpacity};
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  color: ${svars.fontColorBase};
  padding: ${svars.spaceNormal} ${svars.spaceMedium} 0 ${svars.spaceMedium};
  border-radius: ${svars.borderRadius};
  & p {
    left: 0;
  }
`;

const Indicator = styled.span`
  font-size: ${svars.fontSizeBase};
  font-weight: ${svars.fontWeightSemiBold};
  text-align: center;
  user-select: none;
`;
const IndicatorName = styled.span`
  white-space: pre-line;
  text-align: center;
  font-size: ${svars.fontSizeSmaller};
  user-select: none;
`;

function RechartsTooltip({
  data,
  fieldKeys,
  extraFields,
  headerField,
  active,
  payload,
  tooltipContent,
  legendState,
  entityLabelFormatter,
  dateFormatter,
}) {
  const fields = getRechartsTooltipFields(
    fieldKeys,
    entityLabelFormatter,
    extraFields,
    headerField,
    dateFormatter
  );
  if (active && payload) {
    const filteredFields =
      legendState && legendState.clicked
        ? fields.filter((item) => !legendState.clicked.includes(item.key))
        : fields;

    if (data && payload && payload[0] && payload[0].payload) {
      const isDisplayed = (
        (data && data.find(({ id }) => id === payload[0].payload.id)) ||
        {}
      ).displayed;
      if (isDisplayed === false) return null;
    }
    return (
      <TooltipContainer data-html2canvas-ignore="true">
        {tooltipContent !== undefined
          ? tooltipContent
          : filteredFields.map((field) => {
              let { value } = field;
              let meta = {};
              if (
                field.key &&
                payload[field.payloadIndex || 0] &&
                payload[field.payloadIndex || 0].payload
              ) {
                meta = payload[field.payloadIndex || 0].payload;
                value = getDescendantProp(meta, field.key);
              } else if (field.key && payload && payload.length > 1) {
                const fieldPayload = payload.find(
                  ({ dataKey }) => dataKey === field.key
                );
                if (fieldPayload) {
                  value = fieldPayload.value;
                  meta = fieldPayload.payload;
                }
              }
              const labelText = field.label || value;
              const label = labelText ? capitalize(i18n._(labelText)) : '';
              return (
                <span
                  key={`tooltip-${label}-${value}`}
                  style={{
                    color: field.stroke,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    lineHeight: 1.2,
                    paddingBottom: svars.spaceNormalLarge,
                  }}
                >
                  <Indicator data-testid="volume-or-sentiment">
                    {(field.content &&
                      payload &&
                      payload[0] &&
                      field.content(value, payload[0].payload)) ||
                      (value != null &&
                        field.formatter &&
                        field.formatter(value, payload[0].payload)) ||
                      value ||
                      '-'}
                  </Indicator>
                  {field.label && <IndicatorName>{label}</IndicatorName>}
                </span>
              );
            })}
      </TooltipContainer>
    );
  }
  return null;
}

export const tooltipFieldPropTypes = PropTypes.shape({
  key: PropTypes.string,
  label: PropTypes.string,
  stroke: PropTypes.string,
  formatter: PropTypes.func,
});
RechartsTooltip.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  fieldKeys: PropTypes.arrayOf(PropTypes.string),
  extraFields: PropTypes.arrayOf(tooltipFieldPropTypes),
  // An extra field that is displayed at the top position
  headerField: tooltipFieldPropTypes,

  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    })
  ),
  tooltipContent: commonPropTypes.component,
  legendState: PropTypes.shape({
    clicked: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
    ),
  }),
  // To provide if an entity is present in the given `fieldKeys`
  // Form : fieldKey => item => ...
  entityLabelFormatter: PropTypes.func,
  // To enforce date formatter in place of default (which is month format)
  dateFormatter: PropTypes.func,
};

RechartsTooltip.defaultProps = {
  data: null,
  fieldKeys: [],
  extraFields: [],
  headerField: null,
  active: false,
  payload: undefined,
  tooltipContent: undefined,
  legendState: undefined,
  entityLabelFormatter: undefined,
  dateFormatter: null,
};

export default React.memo(RechartsTooltip);
