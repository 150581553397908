import { useLocation, useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const StyledMenu = styled(Menu)`
  &&&& {
    margin: 0 auto;
    flex-grow: 1;
    border-bottom: none;
  }
`;
export const SecondaryNavbarContainer = styled.div`
  width: 100%;
  padding-top: ${svars.spaceSmall};
  background: ${svars.colorWhite};
  box-shadow: ${svars.selectedLightBoxShadow};
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${svars.colorLightGrey};
  &&& div > a {
    color: ${svars.colorPrimary};
    font-weight: ${svars.fontWeightMedium};
  }
`;

const ActionsContainer = styled.span`
  flex-shrink: 0;
  display: inline-flex;
  height: 100%;
  margin: 0 ${svars.spaceNormalLarge};
  align-items: center;
`;

function NavigationTabs({ panes, disabled, children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isCurrent = (path) =>
    path ? pathname.endsWith(path.replace('.', '')) : false;
  return (
    <SecondaryNavbarContainer>
      <StyledMenu
        disabled={disabled}
        pointing
        secondary
        color="blue"
        data-testid="analysis-menu-tab"
      >
        {panes.map(({ to, content, disabled: paneDisabled, icon }, key) => {
          const isCurrentTab = isCurrent(to);
          const finalDisabled = paneDisabled || disabled;
          return (
            <Menu.Item
              disabled={finalDisabled}
              key={`ntmi-${to}`}
              active={isCurrentTab && !finalDisabled}
              onClick={() => !finalDisabled && navigate(to)}
              style={{
                padding: `${svars.spaceNormalLarge} ${svars.spaceMedium}`,
              }}
              data-testid={`bo-analysis-menu-tab-${key}`}
            >
              {icon ? <Icon name={icon} /> : null}
              <Trans render={capitalizedTranslation} id={content} />
            </Menu.Item>
          );
        })}
      </StyledMenu>
      {children ? <ActionsContainer>{children}</ActionsContainer> : null}
    </SecondaryNavbarContainer>
  );
}

NavigationTabs.propTypes = {
  panes: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};
NavigationTabs.defaultProps = {
  children: null,
  disabled: false,
};
export default NavigationTabs;
