import { useCallback, useState } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import canvasToImage from 'canvas-to-image';

import QrCode from 'components/ui/QrCode';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { Checkbox } from 'components/ui/inputs/Checkbox';

import * as svars from 'assets/style/variables';

const getQrCodeElementId = (channelName) =>
  `qrcode-${channelName?.replace(/\s/g, '_')}`;

const onDownloadQrCode = (qrCodeElementId, filename) => () => {
  // Generate download with use canvas and stream
  canvasToImage(document.getElementById(qrCodeElementId), {
    name: `Qrcode-${filename}`,
    type: 'png',
    quality: 1,
  });
};

const CellContainer = styled.div`
display: flex;
flex-direction: column;
  /* border: 1px solid ${svars.colorLightGrey}; */
  box-shadow: {svars.baseBoxShadow};
  border-radius: {svars.borderRadius};
  text-align: right;
  padding: ${svars.spaceNormal};
`;

const CellHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GRID_STEP = '0.5px';
const QrCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${svars.colorLightGrey};
  border-radius: ${svars.borderRadius};
  /* Use evolving gradient as background to show possible backgrounds */
  background-size: 10px 10px;
  background-image: linear-gradient(
      to right,
      ${svars.colorLightGrey} ${GRID_STEP},
      transparent ${GRID_STEP}
    ),
    linear-gradient(
      to bottom,
      ${svars.colorLightGrey} ${GRID_STEP},
      transparent ${GRID_STEP}
    );

  margin: auto;
  margin-top: ${svars.spaceNormal};
`;

function QRCodeCell({
  value,
  formatter,
  small,
  simple,
  defaultDisplayed,
  title,
}) {
  const [whiteAndBorder, setWhiteAndBorder] = useState(true);
  const [displayQrCode, setDisplayQrCode] = useState(defaultDisplayed);
  const elementId = getQrCodeElementId(title);
  const toggleWhiteAndBorder = useCallback(() => {
    setWhiteAndBorder(!whiteAndBorder);
  }, [whiteAndBorder]);
  const onToggleQrCode = useCallback(() => {
    setDisplayQrCode(!displayQrCode);
  }, [displayQrCode]);

  return (
    <CellContainer>
      {!simple ? (
        <CellHeader>
          <Checkbox
            checked={whiteAndBorder}
            label={t`with-white-background-and-margin`}
            onClick={toggleWhiteAndBorder}
            data-testid="bo-channel-panel-margin-bg-checkbox"
          />
          <span style={{ fontSize: svars.fontSizeLarger }}>
            {!defaultDisplayed ? (
              <AnalyticsAwareHoverableIconButtonWithTooltip
                position="top right"
                name="eye"
                help={t`display-qr-code`}
                active={displayQrCode}
                // showTooltipOn="click"
                onClick={onToggleQrCode}
                labelPosition="left"
                data-testid="bo-channel-panel-download-qrcode-button"
              />
            ) : null}
            <AnalyticsAwareHoverableIconButtonWithTooltip
              help={t`download-qr-code`}
              name="download"
              onClick={() => {
                if (!displayQrCode) onToggleQrCode();
                setTimeout(
                  onDownloadQrCode(elementId, title),
                  displayQrCode ? 10 : 300
                );
              }}
              labelPosition="left"
              data-testid="bo-channel-panel-download-qrcode-button"
            />
          </span>
        </CellHeader>
      ) : null}
      {displayQrCode ? (
        <QrCodeContainer>
          <QrCode
            value={formatter?.(value) || value}
            small={small}
            elementId={elementId}
            whiteAndBorder={whiteAndBorder}
          />
        </QrCodeContainer>
      ) : null}
    </CellContainer>
  );
}

QRCodeCell.propTypes = {
  value: PropTypes.string,
  simple: PropTypes.bool,
  small: PropTypes.bool,
  formatter: PropTypes.func,

  // If the QR code should be displayed by default
  defaultDisplayed: PropTypes.bool,
  title: PropTypes.string,
};
QRCodeCell.defaultProps = {
  value: null,
  small: false,
  simple: false,
  formatter: null,
  defaultDisplayed: false,
  title: '',
};

export default QRCodeCell;
