
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { computeNFilters } from 'selectors/search';

import FiltersAccordion from 'components/ui/search/FiltersAccordion';

/**
 * Create a filter component for search 'static' filters (i.e. not tags)
 *
 * @param {Object} filtersValues the current filters values (of all static filters)
 * @param {Function} onFilterChange the function to call when a filter value changes
 * @param {Function} onFilterReset the function to call when a filter is reset
 * @param {Function} getNFilters the function to call to get the number of a filter entries
 * @param {Boolean} loading whether the filters are loading
 * @param {Function} onSetFilterActive the function to call to set the filter as active - this method helps to avoid
 * the filter pane to be closed when interacting with the filter (e.g. when the filter opens a modal)
 * @return {Object} the filter component
 */
const staticFieldFilterFactory = (
  valuesSelector,
  onFilterChange,
  onFilterReset,
  onFilterToggle,
  loading,
  onSetFilterActive
) => {
  function StaticFieldFilter({
    title,
    itemId,
    keys,
    FilterComponent,
    isActive,
  }) {
    const selectedValues = {};
    keys.forEach((key) => {
      selectedValues[key] = useSelector(valuesSelector(key));
    });
    const nFilterValues = computeNFilters(selectedValues);
    return (
      <FiltersAccordion.Filter
        title={title}
        nFilterValues={nFilterValues}
        isActive={isActive}
        onToggle={onFilterToggle(itemId)}
      >
        <FilterComponent
          onFilterChange={onFilterChange}
          onFilterReset={onFilterReset}
          loading={loading}
          onSetFilterActive={onSetFilterActive}
          {...selectedValues}
        />
      </FiltersAccordion.Filter>
    );
  }
  StaticFieldFilter.propTypes = {
    title: PropTypes.string.isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    FilterComponent: PropTypes.elementType.isRequired,
    isActive: PropTypes.bool,
    onToggle: PropTypes.func,
    itemId: PropTypes.string.isRequired,
  };
  StaticFieldFilter.defaultProps = { isActive: false, onToggle: null };
  return StaticFieldFilter;
};

export default staticFieldFilterFactory;
