import React, { useState } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  Area,
  Bar,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  getSvgSentimentGradient,
  getSvgVolumeGradient,
} from 'components/customer/visualization/SvgSentimentGradient';
import interactiveLegend from 'components/ui/visualization/InteractiveLegend';
import RechartsTooltip from 'components/ui/visualization/RechartsTooltip';
import { ComposedChart } from 'components/ui/visualization/StyledChart';

import config from 'config';
import commonPropTypes from 'utils/commonPropTypes';
import {
  floatFormatter,
  monthFormatter,
  numberFormatter,
} from 'utils/formatter';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import CustomizedDot from './CustomizedDot';

const DEFAULT_ORIENTATION = 'left';

const getYAxisLabelProps = (label, orientation) => ({
  value: label,
  angle: 0,
  offset: 15,
  position: 'top',
  style: {
    textAnchor: orientation === 'right' ? 'end' : 'start',
  },
});

const getChildren = (
  childrenAttributes,
  yAxisOrientation,
  sentimentGradientName,
  labelKey
) => {
  const children = [];
  const yAxisIds = new Set();
  const yAxisProps = {};
  (childrenAttributes || []).forEach(({ key, y_id }) => {
    let name;
    let color;
    let label;
    yAxisIds.add(y_id);
    if (!yAxisProps[y_id]) yAxisProps[y_id] = {};
    const labelProps = key === labelKey ? { label: true } : {};
    switch (key) {
      case 'cumulated':
        yAxisProps[y_id].label = getYAxisLabelProps(
          capitalize(t`cumulated-volume`),
          yAxisOrientation[y_id].orientation || DEFAULT_ORIENTATION
        );
        children.push(
          <Area
            type="monotone"
            dataKey="cumulated"
            name={capitalize(t`cumulated-volume`)}
            fill="url(#colorAreaVolume)"
            stroke="url(#colorStrokeVolume)"
            strokeWidth={2.5}
            yAxisId={y_id}
            {...labelProps}
          />
        );
        break;
      case 'n_positive':
      case 'n_negative':
      case 'n_neutral':
        yAxisProps[y_id].label = getYAxisLabelProps(
          capitalize(t`volume`),
          yAxisOrientation[y_id].orientation || DEFAULT_ORIENTATION
        );
        if (key === 'n_positive') {
          name = capitalize(t`positive-volume`);
          color = svars.absoluteMaxColor;
        } else if (key === 'n_negative') {
          name = capitalize(t`negative-volume`);
          color = svars.absoluteMinColor;
        } else if (key === 'n_neutral') {
          name = capitalize(t`neutral-volume`);
          color = svars.absoluteMidColor;
        }
        children.push(
          <Bar
            radius={svars.barRadius}
            dataKey={key}
            name={name}
            barSize={svars.barSize}
            fill={color}
            stackId={1}
            yAxisId={y_id}
            {...labelProps}
          />
        );
        break;
      case 'csat':
        yAxisProps[y_id].domain = [0, 100];
        label = capitalize(t`CSAT-score`);
        yAxisProps[y_id].tickFormatter = numberFormatter;
        yAxisProps[y_id].label = getYAxisLabelProps(
          label,
          yAxisOrientation[y_id].orientation || DEFAULT_ORIENTATION
        );
        children.push(
          <Line
            dataKey={key}
            name={label}
            strokeDasharray="4 3"
            stroke={svars.colorGreyMedium}
            dot={<CustomizedDot />}
            yAxisId={y_id}
            connectNulls
            strokeWidth={1}
            {...labelProps}
          />
        );
        break;
      case 'score':
        label = capitalize(t`score`);
        yAxisProps[y_id].tickFormatter = numberFormatter;
        yAxisProps[y_id].label = getYAxisLabelProps(
          label,
          yAxisOrientation[y_id].orientation || DEFAULT_ORIENTATION
        );
        children.push(
          <Line
            dataKey={key}
            name={label}
            stroke={svars.scoreColor}
            dot
            yAxisId={y_id}
            connectNulls
            strokeWidth={1}
            {...labelProps}
          />
        );
        break;

      case 'average_sentiment':
        label = capitalize(t`sentiment`);
        yAxisProps[y_id].domain = config.SENTIMENT_DOMAIN;
        yAxisProps[y_id].tickFormatter = floatFormatter;
        yAxisProps[y_id].label = getYAxisLabelProps(
          label,
          yAxisOrientation[y_id].orientation || DEFAULT_ORIENTATION
        );
        children.push(
          <Line
            type="monotone"
            dataKey={key}
            name={label}
            stroke={`url(#${sentimentGradientName})`}
            yAxisId={y_id}
            dot={false}
            strokeWidth={5}
            {...labelProps}
          />
        );
        break;
      case 'n_chunks':
      case 'volume':
      default:
        label = capitalize(t`volume`);
        yAxisProps[y_id].label = getYAxisLabelProps(
          capitalize(t`volume`),
          yAxisOrientation[y_id].orientation || DEFAULT_ORIENTATION
        );
        children.push(
          <Bar
            radius={svars.barRadius}
            dataKey={key}
            name={label}
            barSize={svars.barSize}
            fill="url(#colorBarVolume)"
            yAxisId={y_id}
            {...labelProps}
          />
        );

        break;
    }
  });
  yAxisIds.forEach((yAxisId) => {
    const axisProps = yAxisProps[yAxisId];
    children.push(
      <YAxis
        axisLine={{ stroke: svars.chartFontColor }}
        tickLine={{ stroke: svars.chartFontColor }}
        yAxisId={yAxisId}
        allowDecimals={false}
        tickFormatter={numberFormatter}
        width={svars.YAxisWidth}
        {...(yAxisOrientation[yAxisId] || {})}
        {...axisProps}
      />
    );
  });
  return children;
};

const setChartItemsOpacity = (chartChildren, legendState) =>
  React.Children.map(chartChildren, (child) => {
    let opacity = null;
    let opacityProps = {};
    let doClone = false;
    switch (child.type.displayName) {
      case 'Area':
        opacity = interactiveLegend.getOpacity(
          legendState,
          child.props.dataKey,
          0.03,
          1
        );
        opacityProps = {
          fillOpacity: opacity,
          strokeOpacity: opacity,
        };
        doClone = true;
        break;
      case 'Line':
        opacity = interactiveLegend.getOpacity(
          legendState,
          child.props.dataKey,
          0.03,
          1
        );
        opacityProps = {
          strokeOpacity: opacity,
          fillOpacity: opacity,
        };
        // Hide dots when line is hidden
        if (opacity < 0.5) {
          opacityProps.dot = false;
        }
        doClone = true;
        break;
      case 'Bar':
        opacityProps = {
          fillOpacity: interactiveLegend.getOpacity(
            legendState,
            child.props.dataKey,
            0.03,
            0.8
          ),
        };
        doClone = true;
        break;
      default:
        break;
    }
    return doClone ? React.cloneElement(child, opacityProps) : child;
  });

function BaseTimeSeriesVisualization({
  chartId,
  data,
  sentimentGradientName,
  sentiments,
  children,
  childrenAttributes,
  yAxisProps,
  tooltipFieldKeys,
  margins,
  height,
  width,
  dateFormatter,
  hideLegend,
  labelKey,
}) {
  const [legendState, setLegendState] = useState(
    interactiveLegend.getInitialState()
  );
  React.useEffect(() => {}, [legendState]);
  const widthOrHeight = {};
  if (height) {
    widthOrHeight.height = height;
  } else if (width) {
    widthOrHeight.width = width;
  }
  return (
    <ResponsiveContainer
      id={chartId}
      {...widthOrHeight}
      style={{ display: 'flex' }}
    >
      <ComposedChart data={data} margin={margins}>
        {sentimentGradientName &&
          getSvgSentimentGradient(
            sentimentGradientName,
            sentiments,
            false,
            true
          )}
        {getSvgVolumeGradient()}
        <CartesianGrid stroke={svars.cartesianGridStrokeColor} />
        {setChartItemsOpacity(
          children ||
            getChildren(
              childrenAttributes,
              yAxisProps,
              sentimentGradientName,
              labelKey
            ),
          legendState
        )}
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          minTickGap={svars.xAxisMinTickGap}
          tickFormatter={dateFormatter}
          axisLine={{ stroke: svars.chartFontColor }}
          tickLine={{ stroke: svars.chartFontColor }}
          padding={{
            left: svars.xAxisPadding,
            right: svars.xAxisPadding,
          }}
        />
        <Tooltip
          active
          content={
            <RechartsTooltip
              legendState={legendState}
              fieldKeys={tooltipFieldKeys}
              dateFormatter={dateFormatter}
            />
          }
        />
        {hideLegend
          ? null
          : interactiveLegend({
              legendState,
              onStateChange: setLegendState,
            })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

BaseTimeSeriesVisualization.propTypes = {
  chartId: PropTypes.string,
  data: commonPropTypes.timeSeries,
  sentimentGradientName: PropTypes.string,
  sentiments: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node,
  tooltipFieldKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  margins: PropTypes.objectOf(PropTypes.number),
  childrenAttributes: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, y_id: PropTypes.number })
  ),
  yAxisProps: PropTypes.objectOf(
    PropTypes.shape({
      domain: PropTypes.string,
      tickFormatter: PropTypes.func,
      label: PropTypes.string,
      allowDecimals: PropTypes.bool,
    })
  ),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dateFormatter: PropTypes.func,
  hideLegend: PropTypes.bool,
  labelKey: PropTypes.string,
};
BaseTimeSeriesVisualization.defaultProps = {
  sentimentGradientName: undefined,
  sentiments: undefined,
  margins: svars.getChartMargins(),
  data: undefined,
  childrenAttributes: undefined,
  yAxisProps: {},
  children: undefined,
  height: svars.chartHeight,
  width: '100%',
  dateFormatter: monthFormatter,
  chartId: null,
  hideLegend: false,
  labelKey: null,
};

export default BaseTimeSeriesVisualization;
