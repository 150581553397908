import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { entityLabelFormatterSelector } from 'selectors/entities';
import { isComparativeViewFacet } from 'selectors/facet';
import {
  baseProductHierarchyGroupSelector,
  facetSourceGroupSelector,
  viewFacetSelector,
} from 'selectors/view';

import HelpTooltip, { ListHelpTooltip } from 'components/ui/HelpTooltip';
import { SecondaryTabButton } from 'components/ui/button/TabButton';

import commonPropTypes from 'utils/commonPropTypes';
import { dateTimeFormatter, formatPeriod } from 'utils/formatter';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import FlagItem from '../cells/FlagItem';

export const ImgLogo = styled.img`
  &&&&&&& {
    width: ${svars.logoWidth};
  }
`;

function ComparativeProductGroupHelper({
  viewFacet,
  tooltipCommonProps,
  entityLabelFormatter,
}) {
  if (!(viewFacet && viewFacet.comparative_product_hierarchy_groups.length)) {
    return null;
  }
  const commonProps = {
    ...tooltipCommonProps,
  };
  return viewFacet.comparative_product_hierarchy_groups.length > 1 ? (
    <ListHelpTooltip
      hoverable={false}
      {...commonProps}
      title={`${capitalize(t`all-groups`)} :`}
      items={viewFacet.comparative_product_hierarchy_groups}
      getItemText={({ id }) =>
        entityLabelFormatter('productHierarchyGroup', id)
      }
    />
  ) : (
    <ListHelpTooltip
      hoverable={false}
      {...commonProps}
      headerText={viewFacet.comparative_product_hierarchy_groups[0].name}
      title={`${capitalize(t`product(s)-service(s)`)} :`}
      items={viewFacet.comparative_product_hierarchy_groups[0].items}
      getItemText={({ id }) => entityLabelFormatter('productHierarchy', id)}
    />
  );
}

ComparativeProductGroupHelper.propTypes = {
  viewFacet: commonPropTypes.viewFacet,
  entityLabelFormatter: PropTypes.func.isRequired,
  // eslint-disable-next-line
  tooltipCommonProps: PropTypes.object,
};

ComparativeProductGroupHelper.defaultProps = {
  tooltipCommonProps: {},
  viewFacet: null,
};

const getConfigurationSummaryItems = (
  viewFacet,
  facetSourceGroup,
  baseProductHierarchyGroup,
  entityLabelFormatter
) => {
  const tooltipCommonProps = {
    mouseEnterDelay: 100,
    mouseLeaveDelay: 100,
    on: 'hover',
  };
  let facetName = '-';
  let updateDate = '-';
  let baseHierarchyGroupName = '-';
  let sourceGroupName = '-';
  if (viewFacet) {
    facetName = viewFacet.name;
    updateDate = dateTimeFormatter(viewFacet.aggregates_update_date);
    baseHierarchyGroupName = entityLabelFormatter(
      'productHierarchyGroup',
      baseProductHierarchyGroup?.id
    );
    sourceGroupName = facetSourceGroup?.name;
  }
  const items = [
    {
      name: t`analysis`,
      value: facetName,
      helper: (
        <ListHelpTooltip
          hoverable={false}
          {...tooltipCommonProps}
          headerText={facetName}
          items={[
            `${t`creation`} : ${updateDate}`,
            `${t`update`} : ${updateDate}`,
          ]}
          getItemText={(name) => name}
        />
      ),
    },
    {
      name: t`base-group`,
      value: baseHierarchyGroupName,
      helper: (
        <ListHelpTooltip
          hoverable={false}
          {...tooltipCommonProps}
          headerText={baseHierarchyGroupName}
          title={`${capitalize(t`product(s)-service(s)`)} :`}
          items={baseProductHierarchyGroup?.items || []}
          getItemText={({ id }) => entityLabelFormatter('productHierarchy', id)}
        />
      ),
    },
    {
      name: t`comparative-group`,
      hidden: !(viewFacet && isComparativeViewFacet(viewFacet)),
      value:
        (viewFacet &&
          viewFacet.comparative_product_hierarchy_groups &&
          viewFacet.comparative_product_hierarchy_groups
            .map(({ id }) => entityLabelFormatter('productHierarchyGroup', id))
            .join(', ')) ||
        '-',
      helper: (
        <ComparativeProductGroupHelper
          tooltipCommonProps={tooltipCommonProps}
          viewFacet={viewFacet}
          entityLabelFormatter={entityLabelFormatter}
        />
      ),
    },
    {
      name: t`source(s)`,
      value: sourceGroupName,
      helper: (
        <ListHelpTooltip
          hoverable={false}
          {...tooltipCommonProps}
          headerText={sourceGroupName}
          title={`${capitalize(t`source(s)`)} :`}
          items={facetSourceGroup?.items || []}
          getItemText={({ id }) => entityLabelFormatter('source', id)}
        />
      ),
    },
    {
      name: t`restitution-language`,
      value: viewFacet?.restitution_language ? (
        <FlagItem language={viewFacet?.restitution_language} />
      ) : null,
      hidden: !viewFacet?.restitution_language,
    },
    {
      name: t`period`,
      value: viewFacet ? formatPeriod(viewFacet) : '...',
    },
  ];
  return items.filter((item) => !item.hidden);
};

const TooltipSummaryContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex-grow: 100;
  justify-content: space-around;
  padding-right: ${svars.spaceMediumLarge};
`;

const ConfigurationItemContainer = styled.span`
  font-size: ${svars.fontSizeSmall};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: ${svars.spaceSmall} ${svars.spaceNormal} ${svars.spaceNormal}
    ${svars.spaceNormal};
  line-height: 1.3;
  white-space: nowrap;
`;
const ConfigurationItemName = styled.div`
  font-weight: ${svars.fontWeightBase};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ConfigurationValue = styled.div`
  width: 100%;
  display: inline-flex;
  font-weight: ${svars.fontWeightBold};
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ConfigurationValueText = styled.div`
  margin: auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigurationItemFactory = (nSummaryItems) => {
  function ConfigurationItem({ name, value, helper }) {
    return (
      <ConfigurationItemContainer key={`ci-${name}`} nItems={nSummaryItems}>
        <ConfigurationItemName>{capitalize(name)} :</ConfigurationItemName>
        <ConfigurationValue>
          <ConfigurationValueText>{value}</ConfigurationValueText>
          {helper && helper}
        </ConfigurationValue>
      </ConfigurationItemContainer>
    );
  }

  ConfigurationItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    helper: PropTypes.string.isRequired,
  };
  return ConfigurationItem;
};

export function FacetHelperTooltip() {
  const [opened, setOpened] = useState(false);

  const viewFacet = useSelector(viewFacetSelector);
  const entityLabelFormatter = useSelector(entityLabelFormatterSelector);
  const baseProductHierarchyGroup = useSelector(
    baseProductHierarchyGroupSelector
  );
  const facetSourceGroup = useSelector(facetSourceGroupSelector);
  const summaryItems = getConfigurationSummaryItems(
    viewFacet,
    facetSourceGroup,
    baseProductHierarchyGroup,
    entityLabelFormatter
  );
  const summaryItemsFactory = ConfigurationItemFactory(summaryItems.length);
  return (
    <HelpTooltip
      mouseEnterDelay={200}
      mouseLeaveDelay={1000}
      help={
        <TooltipSummaryContainer>
          {summaryItems.map(summaryItemsFactory)}
        </TooltipSummaryContainer>
      }
      hoverable
      inverted={false}
      on="click"
      position="bottom right"
      iconSize={svars.fontSizeLarge}
      open={opened}
      onClose={() => setOpened(false)}
      onOpen={() => setOpened(true)}
      trigger={
        <span>
          <SecondaryTabButton
            active={opened}
            labelPosition="right"
            content={<Trans id="analysis-details" />}
            icon="help"
          />
        </span>
      }
    />
  );
}

FacetHelperTooltip.propTypes = {};
FacetHelperTooltip.defaultProps = {};
