import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import Accordion from 'components/ui/Accordion';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import HelpTooltip from '../HelpTooltip';

const FilterIcon = styled.span`
  line-height: 1.3;
  border-radius: 30%;
  padding: 0 0.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${svars.spaceMedium};
  border: ${svars.accentColor} 1px solid;
  color: ${svars.accentColor};
  font-size: ${svars.fontSizeSmaller};
`;

export function NFiltersIcon({ content }) {
  return content > 0 ? <FilterIcon>{content}</FilterIcon> : null;
}
NFiltersIcon.propTypes = { content: PropTypes.number };
NFiltersIcon.defaultProps = { content: 0 };

const FiltersAccordion = styled(Accordion)`
  &&& {
    transition: ${svars.transitionBase};
    overflow-y: auto;
    border-radius: 0;
    box-shadow: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
FiltersAccordion.defaultProps = {
  styled: true,
  fluid: true,
  exclusive: false,
};

const AccordionContent = styled(Accordion.Content)`
  &&&& {
    max-height: 45vh;
    padding: 0;
    transition: max-height 0.9s ease-out;
    display: ${(props) => (props.active ? 'flex' : 'none')};
    flex-direction: column;
    padding: ${svars.spaceSmall} 0;
    background: ${svars.colorLighterGrey};
  }
`;

const StyledAccordionTitle = styled(Accordion.Title)`
  &&&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${svars.spaceMedium};
    background: ${svars.colorWhite};
    transition: ${svars.transitionBase};
    font-weight: ${svars.fontWeightBase};
    font-size: ${svars.fontSizeMedium};
    ${svars.hoverClickableCss}
    ${svars.activeClickableCss}
  }
`;

function Filter({
  title,
  icon,
  iconHelp,
  isActive,
  onToggle,
  nFilterValues,
  children,
}) {
  return (
    <>
      <StyledAccordionTitle active={isActive} index={0} onClick={onToggle}>
        <span>
          <Icon name="dropdown" />
          {title ? <Trans render={capitalizedTranslation} id={title} /> : null}
          <NFiltersIcon content={nFilterValues} />
        </span>
        {(icon && iconHelp && (
          <HelpTooltip
            compact
            help={<Trans id={iconHelp} />}
            trigger={
              <Icon
                style={{
                  marginLeft: svars.spaceMedium,
                  fontSize: svars.fontSizeLarge,
                }}
                name={icon}
              />
            }
          />
        )) ||
          null}
      </StyledAccordionTitle>
      <AccordionContent active={isActive}>{children}</AccordionContent>
    </>
  );
}
Filter.propTypes = {
  title: PropTypes.string,
  // Icon to be displayed on the right of the title with a help tooltip showing `iconHelp`
  icon: PropTypes.string,
  iconHelp: PropTypes.string,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
  nFilterValues: PropTypes.number,
  children: PropTypes.node,
};
Filter.defaultProps = {
  title: null,
  nFilterValues: 0,
  children: null,
  isActive: null,
  onToggle: null,
  icon: null,
  iconHelp: null,
};
FiltersAccordion.Filter = Filter;

export default FiltersAccordion;
