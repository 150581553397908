import PropTypes from 'prop-types';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const HeaderContainer = styled.span`
  display: inline-flex;
  font-weight: ${svars.fontWeightMedium};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  user-select: none;
  padding: ${svars.spaceNormal} 0;
`;

function BaseReactTableHeader({
  title,
  helpMessage,
  children,
  centered,
  style,
}) {
  let titleComponent = (
    <HeaderContainer centered={centered} style={style}>
      {children || capitalize(title)}
    </HeaderContainer>
  );
  if (helpMessage) {
    const mountNode = document.querySelector('#popup-root');
    titleComponent = (
      <HelpTooltip
        style={{ whiteSpace: 'pre' }}
        help={helpMessage}
        size="tiny"
        baseColor
        marged
        compact
        mouseEnterDelay={550}
        mountNode={mountNode}
        position="top center"
        triggerText={titleComponent}
        // marged={false}
      />
    );
  }
  return titleComponent;
}

BaseReactTableHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, commonPropTypes.i18nText]),
  helpMessage: PropTypes.oneOfType([
    PropTypes.string,
    commonPropTypes.i18nText,
  ]),
  children: PropTypes.node,
  centered: PropTypes.bool,
  style: commonPropTypes.style,
};
BaseReactTableHeader.defaultProps = {
  helpMessage: null,
  title: undefined,
  children: null,
  centered: false,
  style: null,
};

export default BaseReactTableHeader;
