import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import ColoredDot from 'components/ui/ColoredDot';
import { LimitedTextCell } from 'components/ui/Text';

import { getCSATScoreColor, getSentimentCategoryColor } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';
import { numberOnHundredFormatter } from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const cellPropTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
};

export function ColorDotCell({ value, style }) {
  return (
    <LimitedTextCell style={{ display: 'block', ...style }}>
      {value}
    </LimitedTextCell>
  );
}

ColorDotCell.propTypes = {
  ...cellPropTypes,
  style: commonPropTypes.style,
};
ColorDotCell.defaultProps = { style: {} };

function activityIndicatorCellFactory(valueFormatter, colorFormatter) {
  function BaseActivityIndicatorCell({ value, style, color }) {
    return (
      <LimitedTextCell
        style={{ display: 'inline-flex', alignItems: 'center', ...style }}
      >
        <ColoredDot
          color={
            color ||
            colorFormatter?.(value) ||
            (value && value !== '-' && svars.accentColor) ||
            svars.colorLightGrey
          }
          style={{ marginRight: svars.spaceSmaller }}
        />
        {valueFormatter?.(value) || value || 0}
      </LimitedTextCell>
    );
  }
  BaseActivityIndicatorCell.propTypes = {
    ...cellPropTypes,
    style: commonPropTypes.style,
  };
  BaseActivityIndicatorCell.defaultProps = { style: {} };
  return BaseActivityIndicatorCell;
}

export const WarningIndicatorCell = activityIndicatorCellFactory(
  (value) => value,
  () => svars.colorWarning
);
export const ActivityIndicatorCell = activityIndicatorCellFactory();
export const CSATCell = activityIndicatorCellFactory(
  (value) => numberOnHundredFormatter(value, svars.fontSizeSmall),
  getCSATScoreColor
);

const CAMPAIGN_STATUS_TEXTS = {
  active: t`active`,
  draft: t`draft`,
  archived: t`archived`,
};
export function CampaignStatusCell({ value, style }) {
  return (
    <ActivityIndicatorCell
      value={
        <Trans
          id={CAMPAIGN_STATUS_TEXTS?.[value] || ''}
          render={capitalizedTranslation}
        />
      }
      color={
        (value === 'active' && svars.colorSuccess) ||
        (value === 'draft' && svars.colorWarning) ||
        svars.colorGreyMedium
      }
      style={{
        ...style,
      }}
    />
  );
}
CampaignStatusCell.propTypes = {
  ...cellPropTypes,
  style: commonPropTypes.style,
};
CampaignStatusCell.defaultProps = { style: {} };

export function SentimentDotCell({ value }) {
  return (
    <ColoredDot
      sentiment={1}
      style={{
        backgroundColor: getSentimentCategoryColor(value),
        // margin: 'auto 0',
      }}
    />
  );
}
SentimentDotCell.propTypes = { value: PropTypes.number.isRequired };
